"use client";

import Image from "@/components/common/Image";
import Typewriter from "@/components/components/Typewriter";
import Link from "next/link";

const messages = [
  "Oh no! The page you're looking for seems to have been swallowed by the abyss of the internet. But fear not! While we fish it out of the digital ether, why not let a Thinknado rip through your brain? Who knows, maybe the solution is a whirlwind of ideas away.",
  "This page might have taken a wrong turn at the Bermuda Triangle of the web. Before we set sail to rescue it, how about stirring up a Thinknado? It’s like a storm, but with ideas instead of rain. You could end up somewhere brilliant—or at least somewhere interesting!",
  "Oops! It seems this page couldn’t handle the sheer force of our awesomeness and just... vanished. Let’s channel that energy into something more productive. Fire up a Thinknado, and let’s see if we can generate a solution so mind-bending, it’ll bring the page back out of sheer awe.",
  "Sometimes pages like to hide, much like your keys or that one sock. While we’re on the hunt, why not shake up your synapses with a Thinknado? It’s the perfect way to blow away those mental cobwebs and find a new path forward—hopefully with your page in tow.",
  "Looks like this page is out to lunch, possibly with your lost marbles. While it’s taking its sweet time, why not roll the dice on a Thinknado? Who knows, you might stumble upon something even better than what you were looking for.",
  "Looks like Mario took the page to a different castle. Instead of chasing pixels, why not kickstart a Thinknado and power up your creativity? By the time you're done, you'll have ideas even Bowser would be jealous of.",
  "This page couldn’t handle the sheer brilliance you were bringing. But don’t let a little digital ghosting stop you! Unleash a Thinknado and blow away the error with a storm of fresh ideas. It’s like spring cleaning for your brain, but with more chaos and fewer dust bunnies.",
  "Let’s be real—if this page disappeared, it’s probably because it couldn’t keep up with your vibe. While we reel it back from the void, why not start a Thinknado? It’s like brainstorming on a roller coaster—wild, unpredictable, and a lot more fun than your average error message.",
  "This page has taken a walk on the wild side and isn’t picking up its phone. No worries, though! Let’s use this opportunity to unleash a Thinknado, get those creative juices flowing, and maybe even invent a new way to solve problems that don’t involve finding missing pages.",
  "This page has gone off the grid, but that’s no reason to stay stuck. Let’s take this as a sign to go rogue ourselves—with a Thinknado! Conjure up some fresh, chaotic ideas and leave the page hunting to us. Who knows, maybe you’ll discover something even better.",
];

export default function NotFound() {
  const getRandomMessage = () => {
    return messages[Math.floor(Math.random() * messages.length)];
  };
  return (
    <div className="flex flex-col gap-4 justify-center items-center text-lg">
      <Image
        src="/Sall-e2point001.png"
        alt="Sall-e"
        width={400}
        height={400}
        priority
      />

      <div className="max-w-full w-[600px] relative top-[-1.5rem] text-center flex flex-col gap-4">
        <div className=" bg-[#CF4C67] text-white p-4 rounded-xl text-xl min-h-[55px] font-medium">
          <Typewriter text={getRandomMessage()} delay={50} />
        </div>
      </div>
    </div>
  );
}
